import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useEffect, useRef, useState } from 'react';
import { Animated } from "react-animated-css";
import NumberFormat from 'react-number-format';
import { Redirect, useHistory, Link } from 'react-router-dom';
import { connect } from "react-redux";
import { getAllTypes } from "../utils"
import { getItems, getSliders } from '../initLoad';
import { useMediaQuery } from 'react-responsive'

const Front = ({ items, categories, itemTypes }) => {

    const startPosition = useRef(0);

    const [slidesElement, setSlidesElement] = useState([])

    const [actionItems, setActionItems] = useState(null);
    const [actionFilterElement, setActionFilterElement] = useState([]);

    const filterElement = [{ id: 0, categor: "Összes" }, ...getAllTypes(items, categories)];

    const [itemFilter, setItemFilter] = useState(filterElement[0].id);
    const [actionItemFilter, setActionItemFilter] = useState(actionFilterElement[0]);

    useEffect(() => {
        getSliders(setSlidesElement)
        getItems(setActionItems, 9, true);
    }, [])

    useEffect(() => {
        if (actionItems) {
            setActionFilterElement([{ id: 0, categor: "Összes" }, ...getAllTypes(actionItems, categories)])
            setActionItemFilter(0)
        }
    }, [actionItems])


    const max480 = useMediaQuery({ query: '(max-width: 480px)' });
    const max800 = useMediaQuery({ query: '(max-width: 800px)' });
    const max1024 = useMediaQuery({ query: '(max-width: 1024px)' });

    const reponseiveStyle = (paths) => {
        if (max480) {
            return { "backgroundImage": 'url(' + paths[480] + ')' }
        }
        if (max800) {
            return { "backgroundImage": 'url(' + paths[800] + ')' }
        }
        if (max1024) {
            return { "backgroundImage": 'url(' + paths[1024] + ')' }
        }
        return { "backgroundImage": 'url(' + paths.full + ')' }
    }

    return (
        <>
            <section class="wow bounceInUp welcome_area container">
                <OwlCarousel class="welcome_slides owl-carousel"
                    startPosition={startPosition.current} loop
                    items={1}
                    autoplay
                    autoplayTimeout={7000}
                    smartSpeed={1000}
                    onTranslate={({ page }) => {
                        startPosition.current = page.index;
                        setSlidesElement([...slidesElement])
                    }}
                >
                    {slidesElement.map(sE => (
                        <div class="item single_slide bg-img background-overlays" style={reponseiveStyle(sE.imagesPaths)}>
                            <div class="container h-100">
                                <div class="row h-100">
                                    <div class="col-12">
                                        <div class="welcome_slide_text">
                                            <Animated animationIn="bounceInDown" isVisible={true} animationInDelay={0} animationInDuration={500}><h6>{sE.subtitle}</h6></Animated>
                                            <Animated animationIn="fadeInUp" isVisible={true} animationInDelay={500} animationInDuration={500}><h2>{sE.title}</h2></Animated>
                                            <Animated animationIn="fadeInUp" isVisible={true} animationInDelay={1} animationInDuration={500}><a href={sE.url} class="btn special-btn">{sE.button}</a></Animated>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </OwlCarousel>
            </section>

            <section class="new_arrivals_area section_padding_100_0 clearfix">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="section_heading text-center wow fadeInDown">
                                <h2>Legújabb termékeink</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="special-projects-menu mb-100">
                    <div class="text-center portfolio-menu">
                        {filterElement && filterElement.map(filter =>
                            <button className={"wow fadeInUp btn" + (filter.id === itemFilter ? " active" : "")} onClick={() => setItemFilter(filter.id)}>{filter.categor}</button>
                        )}
                    </div>
                </div>
                <div class="container">
                    <div class="row">
                        {items && items.map(item => (
                            <div className={"col-12 col-sm-6 col-md-4 single_gallery_item  wow fadeInUpBig " + (item.action_price && item.action_price > 0 ? "discount" : "") + (itemFilter !== 0 && itemFilter !== item.categories ? "hide" : "")} data-wow-delay="0.2s">
                                <Link to={"/termek/" + item.url}>
                                    <div class="product-img">
                                        <img src={item.images[0]?.fullPath || ""} alt="" />
                                        <div class="product-quicview">
                                            Részletek megtekintése
                                        </div>
                                        {item.action_price && item.action_price > 0 && <div class="discount-product">
                                            Akciós termék
                                        </div>}
                                    </div>

                                    <div class="product-description">
                                        <h4 class="product-price">
                                            {item.action_price && item.action_price > 0 && <NumberFormat value={item.action_price} displayType={'text'} thousandSeparator={" "} suffix={'Ft'} />}
                                            <NumberFormat value={item.price} displayType={'text'} thousandSeparator={" "} suffix={'Ft'} />
                                        </h4>
                                        <p>{item.name} {item.year && <span class="badge bg-success"> {item.year}</span>}</p>
                                        <Link to={"/termek/" + item.url} class="add-to-cart-btn">Részletek</Link>
                                    </div>
                                </Link>
                            </div>)
                        )}
                    </div>
                </div>
            </section>

            <div class="line"></div>

            {actionItems && <section class="new_arrivals_area section_padding_100_0 clearfix">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="section_heading text-center wow fadeInDown">
                                <h2>Akciók</h2>
                            </div>
                        </div>
                    </div>
                </div>
                {actionFilterElement && <div class="special-projects-menu mb-100">
                    <div class="text-center portfolio-menu">
                        {actionFilterElement.map(filter =>
                            <button className={"wow fadeInUp btn" + (filter.id === actionItemFilter ? " active" : "")} onClick={() => setActionItemFilter(filter.id)}>{filter.categor}</button>
                        )}
                    </div>
                </div>}
                <div class="container">
                    <div class="row">
                        {actionItems && actionItems.map(item => (
                            <div className={"col-12 col-sm-6 col-md-4 single_gallery_item  wow fadeInUpBig " + (item.action_price && item.action_price > 0 ? "discount" : "") + (actionItemFilter !== 0 && actionItemFilter !== item.categories ? "hide" : "")} data-wow-delay="0.2s">
                                <Link to={"/termek/" + item.url}>
                                    <div class="product-img">
                                        <img src={item.images[0]?.fullPath || ""} alt="" />
                                        <div class="product-quicview">
                                            Részletek megtekintése
                                        </div>
                                        {item.action_price && item.action_price > 0 && <div class="discount-product">
                                            Akciós termék
                                        </div>}
                                    </div>

                                    <div class="product-description">
                                        <h4 class="product-price">
                                            {item.action_price && item.action_price > 0 && <NumberFormat value={item.action_price} displayType={'text'} thousandSeparator={" "} suffix={'Ft'} />}
                                            <NumberFormat value={item.price} displayType={'text'} thousandSeparator={" "} suffix={'Ft'} />
                                        </h4>
                                        <p>{item.name} {item.year && <span class="badge bg-success"> {item.year}</span>}</p>
                                        <Link to={"/termek/" + item.url} class="add-to-cart-btn">Részletek</Link>
                                    </div>
                                </Link>
                            </div>)
                        )}
                    </div>
                </div>
            </section>}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        categories: (state.form.categories || []),
        itemTypes: (state.form.itemTypes || []),
        items: (state.form.items || []),
    };
};
export default connect(mapStateToProps)(Front);
