import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { getMenus } from "../initLoad";

const SubMenuItem = ({ actual, menus }) => {
    const child = menus.filter(m => parseInt(m.parent) === parseInt(actual.menu));
    if (child.length > 0) {
        return (
            <li class="nav-item dropdown">
                {actual.type === "url" && <a class="nav-link dropdown-toggle" href={actual.url} id="specialDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{actual.name}</a>}
                {actual.type !== "url" && <Link class="nav-link dropdown-toggle" to={actual.url} id="specialDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{actual.name}</Link>}
                <div class="dropdown-menu" aria-labelledby="specialDropdown">

                    {
                        child.map(subMenu => {
                            if (subMenu.type === "url") {
                                return <a class="dropdown-item" href={subMenu.url}>{subMenu.name}</a>
                            }
                            return <Link class="dropdown-item" to={subMenu.url}>{subMenu.name}</Link>
                        })
                    }
                </div>
            </li>
        )
    }
    else {
        if (actual.type === "url") {
            return <li class="nav-item"><a class="nav-link" href={actual.url}>{actual.name}</a></li>
        }
        return <li class="nav-item"><Link class="nav-link" to={actual.url}>{actual.name}</Link></li>
    }
}

const Header = () => {
    const [menus, setMenus] = useState([]);
    useEffect(() => {
        getMenus(setMenus)
    }, [])
    const menuItems = [
        {
            name: 'Újdonságok',
            url: '/termekek/Újdonságok'
        },
        {
            name: 'Használt lego',
            url: '/termekek/Használt lego',
            items: [
                {
                    name: 'Készlet',
                    url: '/termekek/Használt lego készletek'
                },
                {
                    name: 'Kiegészítők',
                    url: '/termekek/Használt lego kiegészítők'
                },
                {
                    name: 'Alkatrészek',
                    url: '/termekek/Használt lego alkatrészek'
                },
            ]
        }, {
            name: 'Új lego',
            url: '/termekek/Új lego',
            items: [
                {
                    name: 'Új készlet',
                    url: '/termekek/Új lego készletek'
                },
                {
                    name: 'Új kiegészítők',
                    url: '/termekek/Új lego kiegészítők'
                },
                {
                    name: 'Új alkatrészek',
                    url: '/termekek/Új lego alkatrészek'
                },
            ]
        },
        {
            name: 'Újságok, könyvek',
            url: '/termekek/Újságok, könyvek'
        },
        { name: 'Akciók' },
        { name: 'Kapcsolat' }
    ];
    return (
        <header class="wow fadeInDownBig header_area">
            <div class="top_header_area">
                <div class="container h-100">
                    <div class="row h-100 align-items-center justify-content-center">
                        <div class="col-12 col-lg-7">
                            <div class="top_single_area d-flex align-items-center">
                                <div class="top_logo">
                                    <Link to="/"><img src="/logo.svg" alt="logo" className="logo" /></Link>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="main_header_area">
                <div class="container h-100">
                    <div class="row h-100">
                        <div class="col-12 d-md-flex justify-content-center">
                            <div class="main-menu-area">
                                <nav class="navbar navbar-expand-lg align-items-start">
                                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#special-navbar" aria-controls="special-navbar" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"><i class="ti-menu"></i></span></button>
                                    <div class="collapse navbar-collapse align-items-start collapse" id="special-navbar">
                                        <ul class="navbar-nav animated" id="nav">
                                            {menus && menus.filter(m => parseInt(m.parent) === 0).map(menu => {
                                                return <SubMenuItem actual={menu} menus={menus} />
                                            })}
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
};

export default Header