import axios from "axios";

export const getMenus = async (setMenus) => {
    const res = await axios.get('/get/menus');

    const menus = await res;
    if (menus.status === 200) {
        setMenus(menus.data)
    }
}

export const getCategories = async (setCategories) => {
    const res = await axios.get('/get/categories');

    const categories = await res;
    if (categories.status === 200) {
        setCategories(categories.data)
    }
}
export const getSliders = async (setSliders) => {
    const res = await axios.get('/get/sliders');

    const sliders = await res;
    if (sliders.status === 200) {
        setSliders(sliders.data)
    }
}
export const getFooters = async (setFooters) => {
    const res = await axios.get('/get/footers');

    const footers = await res;
    if (footers.status === 200) {
        setFooters(footers.data)
    }
}

export const getItems = async (setItems, count = 9, action = false) => {
    let res = null;
    if (action === false) {
        res = await axios.get('/get/items');
    }
    else {
        res = await axios.get('/get/actionitems');
    }
    const items = await res;
    if (items.status === 200) {
        if (action && items.data.length === 0) {
            setItems(null)
        }
        else {
            setItems(items.data)
        }
    }
}

export const getItem = async (path, setItem) => {
    const res = await axios.get('/get/item/' + path);

    const item = await res;
    if (item.status === 200) {
        setItem(item.data)
    }
}

export const getItemTypes = async (setItemTypes) => {
    const res = await axios.get('/get/itemtypes');

    const itemTypes = await res;
    if (itemTypes.status === 200) {
        setItemTypes(itemTypes.data);
    }
}

export const getCategoiresItems = async (path, filter) => {
    const data = new FormData();
    data.append("path", path);
    data.append("filter", JSON.stringify(filter));
    const res = await axios.post("/get/filtered_item", data);
    const itemTypes = await res;
    if (itemTypes.status === 200) {
        return itemTypes.data;
    }
    return { name: "Nincs ilyen termék", items: [] }
}


export const getPage = async (url, setPage) => {
    const res = await axios.get('/get/page' + url);

    const page = await res;
    if (page.status === 200) {
        setPage(page.data)
    }
    else { setPage(false) };
}

export const getSystems = async (setSystems) => {
    const res = await axios.get('/get/sdata');

    const systems = await res;
    if (systems.status === 200) {
        setSystems(systems.data)
    }
}

export const getFeaturedItems = async (itemURL, setFItems) => {
    const res = await axios.get('/get/featureditems/' + itemURL);

    const items = await res;
    if (items.status === 200) {
        setFItems(items.data)
    }
}