import { connect } from "react-redux";
import ReactHtmlParser from 'react-html-parser';

const Footer = ({ footers, systems }) => {
    return (
        <footer class="footer_area">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-3 col-lg-2">
                        <div class="single_footer_area">
                            {footers[0] && footers[0].map(footer => {
                                if (footer.type === "T") {
                                    return ReactHtmlParser(footer.value)
                                };
                                return <a href={footer.link}>{footer.value}</a>
                            }
                            )}
                        </div>
                    </div>

                    <div class="col-12 col-sm-6 col-md-3 col-lg-2">
                        <div class="single_footer_area">
                            {footers[1] && footers[1].map(footer => {
                                if (footer.type === "T") {
                                    return ReactHtmlParser(footer.value)
                                };
                                return <a href={footer.link}>{footer.value}</a>
                            }
                            )}
                        </div>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4">
                        <div class="single_footer_area">
                            <div class="footer-logo">
                                <img src="/logo.svg" alt="" />
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-lg-4">
                        <div class="single_footer_area">
                            {footers[3] && footers[3].map(footer => {
                                if (footer.type === "T") {
                                    return ReactHtmlParser(footer.value)
                                };
                                return <a href={footer.link}>{footer.value}</a>
                            }
                            )}
                        </div>
                    </div>

                </div>
                <div class="line"></div>


                <div class="footer_bottom_area">
                    <div class="row">
                        <div class="col-12">
                            <div class="footer_social_area text-center">
                                {systems.filter(system => system.type === "social" && system.content.startsWith("http")).map(system => <a href={system.content}><i class={`fa fa-${system.name}`} aria-hidden="true"></i></a>)}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="line"></div>
                <div className="copyright-footer">
                    {systems && systems.filter(system => system.type === "footer").map(system => ReactHtmlParser(system.content))}
                    <br />
                    Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved | Created by Webspecial
                </div>
            </div>
        </footer>
    )
}

const mapStateToProps = (state) => {
    return {
        footers: (state.form.footers || []),
        systems: (state.form.systems || [])
    };
};
export default connect(mapStateToProps)(Footer);
