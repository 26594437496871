import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import NumberFormat from 'react-number-format';
import InfiniteScroll from "react-infinite-scroll-component";


const Category = ({ items, name, getNextPage, hasMore, filterCategories, filterTypes, filterList, setFilterList, short, setShort, filterYears }) => {
    const history = useHistory();
    const changePath = (path) => {
        window.scrollTo(0, 0);
        history.push(path);
    }
    //const [short, setShort] = useState("date-asc");
    const shortObject = (obj, key) => {
        return obj.sort((a, b) => a.name.toString().localeCompare(b.name));
    }

    const filter = (name, type) => {
        const tmpFilterList = { ...filterList };
        if (tmpFilterList[type].includes(name)) {
            tmpFilterList[type].splice(tmpFilterList[type].indexOf(name), 1);
        }
        else {
            tmpFilterList[type].push(name)
        }
        setFilterList(tmpFilterList);
        /* const randomElement = newItems.filter(i => Math.floor(Math.random() * newItems.length) % 2 === 0 ? i : false)
        setItemList(randomElement); */
    }

    return (<>
        <section className="shop_grid_area">
            <div className="line"></div>
            <div className="container">
                <div class="row">
                    <div class="col-12">
                        <div class="section_heading text-center wow fadeInDown">
                            <h2>{name}</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="line"></div>
            <div className="container item-list">
                <div className="row" key={short}>
                    <div className="col-12 col-md-4 col-lg-3">
                        <div className="shop_sidebar_area">
                            {filterTypes.length > 0 && <div className="widget catagory mb-25">
                                <div className="nav-side-menu">
                                    <h6 className="mb-0">Kategóriák</h6>
                                    <div className="menu-list">
                                        <ul id="menu-content2" className="menu-content">
                                            {shortObject(filterTypes, "name").map(item => <li className={"filter-item " + (filterList.t.includes(item.type_id) ? "active" : "")}><a onClick={() => filter(item.type_id, "t")}>{item.name} ({item.c})</a></li>)}
                                        </ul>
                                    </div>
                                </div>
                            </div>}
                            {filterCategories.length > 0 && <div className="widget catagory mb-25">
                                <div className="nav-side-menu">
                                    <h6 className="mb-0">Téma</h6>
                                    <div className="menu-list">
                                        <ul id="menu-content2" className="menu-content">
                                            {shortObject(filterCategories, "name").map(item => <li className={"filter-item " + (filterList.c.includes(item.categories) ? "active" : "")}><a onClick={() => filter(item.categories, "c")}>{item.name} ({item.c})</a></li>)}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            }
                            {filterYears.length > 0 && <div className="widget catagory mb-25">
                                <div className="nav-side-menu">
                                    <h6 className="mb-0">Megjelenés</h6>
                                    <div className="menu-list">
                                        <ul id="menu-content2" className="menu-content">
                                            {filterYears.map(item => <li className={"filter-item " + (filterList.y.includes(item.name) ? "active" : "")}><a onClick={() => filter(item.name, "y")}>{item.name} ({item.c})</a></li>)}
                                        </ul>
                                    </div>
                                </div>
                            </div>}


                        </div>
                    </div>
                    <div className="col-12 col-md-8 col-lg-9">
                        {items && items.length > 0 && <>
                            <div className="short-container" style={{ textAlign: "right", marginBottom: 20 }}>
                                <label>Termék rendezés </label>
                                <select onChange={(e) => setShort(e.target.value)} value={short} className="form-control" style={{ width: "200px", display: "inline", marginLeft: 10 }}>
                                    <option value="date-desc">Legújabb elöl</option>
                                    <option value="price-asc">Ár szerint növekvő</option>
                                    <option value="name-asc">Név szerint növekvő</option>
                                    {filterYears && <>
                                        <option value="year-asc">Évjárat szerint növekvő</option>
                                        <option value="year-desc">Évjárat szerint csökkenő</option>
                                    </>}
                                    <option value="name-desc">Név szerint csökkenő</option>
                                    <option value="price-desc">Ár szerint csökkenő</option>
                                    <option value="date-asc">Legrégebbi elöl</option>
                                </select>
                            </div>
                            <InfiniteScroll
                                dataLength={items.length}
                                next={getNextPage}
                                hasMore={!hasMore}
                                loader={<h4>Betöltés...</h4>}
                            >
                                <div className="shop_grid_product_area">
                                    <div className="row">

                                        {items.map(item => (
                                            <div className={"col-12 col-sm-6 col-lg-4 single_gallery_item wow fadeInUpBig " + (item.action_price && item.action_price > 0 ? "discount" : "")} onClick={() => changePath("/termek/" + item.url)}>
                                                <div className="product-img">
                                                    <img src={item.images[0]?.fullPath || ""} alt="" />
                                                    <div className="product-quicview">
                                                        <Link to={"/termek/" + item.url} ><i className="ti-receipt"></i></Link>
                                                    </div>
                                                    {item.action_price && item.action_price > 0 && <div class="discount-product">
                                                        Akciós termék
                                                    </div>}
                                                </div>
                                                <div className="product-description">
                                                    <h4 class="product-price">
                                                        {item.action_price && item.action_price > 0 && <NumberFormat value={item.action_price} displayType={'text'} thousandSeparator={" "} suffix={'Ft'} />}
                                                        <NumberFormat value={item.price} displayType={'text'} thousandSeparator={" "} suffix={'Ft'} />
                                                    </h4>
                                                    <p>{item.name} {item.year && <span class="badge bg-success"> {item.year}</span>}</p>
                                                    <Link to={"/termek/" + item.url} className="add-to-cart-btn">Részletek</Link>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </InfiniteScroll>
                        </>}
                        {!items || items.length <= 0 && <h3 style={{ textAlign: "center" }}>Sajnos nincs ilyen termék!</h3>}
                    </div>
                </div>

            </div>
        </section >

    </>)
}

export default Category;