//Get item category
export const getItemCategor = (item, categories) => {
    const categorKey = categories.findIndex((i) => parseInt(i.id) === parseInt(item.categories));
    if (categorKey >= 0) {
        return categories[categorKey].name;
    }
    return "";
}

function sortOn(property) {
    return function (a, b) {
        if (a[property] < b[property]) {
            return -1;
        } else if (a[property] > b[property]) {
            return 1;
        } else {
            return 0;
        }
    }
}

//Get all types in items list.
export const getAllTypes = (items, categories) => {
    const response = [];
    for (let item of items) {
        const categorKey = categories.findIndex((i) => parseInt(i.id) === parseInt(item.categories));
        if (categorKey >= 0) {
            const id = categories[categorKey].id;
            const categor = categories[categorKey].name;

            const puhedID = response.findIndex((i) => parseInt(i.id) === parseInt(id));
            if (puhedID < 0) {
                response.push({ id: id, categor: categor });
            }
        }
    }
    response.sort(sortOn("categor"));
    return response;
}