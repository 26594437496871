import NumberFormat from 'react-number-format';
import { useHistory, Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { getItem, getFeaturedItems } from '../initLoad';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import ImageGallery from 'react-image-gallery';

import "react-image-gallery/styles/css/image-gallery.css";

const Details = ({ match, categories, systems }) => {
    const [item, setItem] = useState(null)
    const history = useHistory();
    const changePath = (path) => {
        window.scrollTo(0, 0);
        history.push(path);
    }
    const { product } = match.params;
    useEffect(() => {
        getItem(product, setItem);
    }, [])
    useEffect(() => {
        getItem(product, setItem);
    }, [match])
    useEffect(() => {
        getFeaturedItems(product, setFeaturedItems)
        const filterdCategor = categories && item && categories?.find(c => c.id === item.categories) || null;
        if (filterdCategor) {
            setCategor(filterdCategor)
        }
    }, [item])
    const [featuredItems, setFeaturedItems] = useState(null);

    const [categor, setCategor] = useState(null);

    return (<>
        {item && <>
            <div class="breadcumb_area">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <ol class="breadcrumb d-flex align-items-center">
                                <li class="breadcrumb-item"><Link to="/">Főoldal</Link></li>
                                {categor && <li class="breadcrumb-item"><Link to={"/termekek/" + categor.url}>{categor.name}</Link></li>}
                                <li class="breadcrumb-item active">{item.name}</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section class="single_product_details_area section_padding_0_100">
                <div class="container">
                    <div class="row">

                        <div class="col-12 col-md-6">
                            {item.images && <ImageGallery
                                items={item.images.map(image => {
                                    return {
                                        original: `/images/items/full/${image.filename}`,
                                        thumbnail: `/images/items/800/${image.filename}`
                                    }
                                })}
                                showPlayButton={false}
                            />}
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="single_product_desc">
                                <h4 class="title"><a href="#">{item.name}</a></h4>
                                {item.year && <span class="badge bg-success"> Megjelenés éve: {item.year}</span>}<br />
                                {item.piece && <span class="badge bg-secondary"> {item.piece} darabos készlet</span>}
                                <h4 class="price">

                                    {item.action_price && <NumberFormat value={item.action_price} displayType={'text'} thousandSeparator={" "} suffix={'Ft'} />}
                                    {<NumberFormat value={item.price} displayType={'text'} thousandSeparator={" "} suffix={'Ft'} />}

                                </h4>

                                <div id="accordion" role="tablist">
                                    <div class="card">
                                        <div class="card-header" role="tab" id="headingOne">
                                            <h6 class="mb-0">
                                                <a data-toggle="collapse" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Termék leírás</a>
                                            </h6>
                                        </div>

                                        <div id="collapseOne" class="collapse show" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion">
                                            <div class="card-body">
                                                {ReactHtmlParser(item.description)}
                                            </div>
                                        </div>
                                    </div>
                                    {systems.filter(system => system.type === "details").map(system => (
                                        <div class="card">
                                            <div class="card-header" role="tab" id={`heading-${system.name}`}>
                                                <h6 class="mb-0">
                                                    <a class="collapsed" data-toggle="collapse" href={`#${system.name}`} aria-expanded="false" aria-controls={system.name}>{system.title}</a>
                                                </h6>
                                            </div>
                                            <div id={system.name} class="collapse" role="tabpanel" aria-labelledby={`heading-${system.name}`} data-parent="#accordion">
                                                <div class="card-body">
                                                    {ReactHtmlParser(system.content)}
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div class="line"></div>
            {featuredItems && <section class="you_may_like_area clearfix">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="section_heading text-center">
                                <h2>Hasonló termékek</h2>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <OwlCarousel class="you_make_like_slider owl-carousel"
                                startPosition={0} loop
                                responsive={{
                                    0: {
                                        items: 2
                                    },
                                    400: {
                                        items: 3
                                    },
                                    1000: {
                                        items: 4
                                    }
                                }}
                                autoplay
                                autoplayTimeout={7000}
                                smartSpeed={1000}
                            >
                                {featuredItems.map(item => (
                                    <div className={(item.action_price && item.action_price > 0 ? "discount" : "")} onClick={() => changePath("/termek/" + item.url)}>
                                        <div class="product-img">
                                            <img src={item.images[0]?.fullPath || ""} alt="" />
                                            <div class="product-quicview">
                                                Részletek megtekintése
                                            </div>
                                            {item.action_price && item.action_price > 0 && <div class="discount-product">
                                                Akciós termék
                                            </div>}
                                        </div>

                                        <div class="product-description">
                                            <h4 class="product-price">
                                                {item.action_price && item.action_price > 0 && <NumberFormat value={item.action_price} displayType={'text'} thousandSeparator={" "} suffix={'Ft'} />}
                                                <NumberFormat value={item.price} displayType={'text'} thousandSeparator={" "} suffix={'Ft'} />
                                            </h4>
                                            <p>{item.name}</p>
                                            <Link to={"/termek/" + item.url} class="add-to-cart-btn">Részletek</Link>
                                        </div>
                                    </div>
                                ))}
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </section>}
        </>}
    </>)
}

const mapStateToProps = (state) => {
    return {
        categories: (state.form.categories || []),
        itemTypes: (state.form.itemTypes || []),
        systems: (state.form.systems || [])
    };
};
export default connect(mapStateToProps)(Details);