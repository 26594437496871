import './App.css';
import Header from './Templates/Header'
import Front from './Templates/Front'
import Page from './Templates/Page'
import Footer from './Templates/Footer'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Details from './Templates/Details';
import { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { getCategories, getItemTypes, getItems, getFooters, getSystems } from './initLoad';
import ItemsList from './Templates/ItemsList';

function App({ handleCategories, handleItems, handleItemTypes, handleFooters, handleSystems }) {
  useEffect(async () => {
    await getCategories(handleCategories);
    await getItemTypes(handleItemTypes);
    await getItems(handleItems);
    await getFooters(handleFooters);
    await getSystems(handleSystems);
  }, [])
  return (
    <>
      <Router>
        <Header />
        <Switch>
          <Route path="/termekek/:name" render={(props) => <ItemsList {...props} />}>
          </Route>
          <Route path="/termek/:product" render={(props) => <Details {...props} />}>
          </Route>
          <Route path="/:url" render={(props) => <Page {...props} />}>
          </Route>
          <Route path="/">
            <Front />
          </Route>
        </Switch>
      </Router>
      <Footer />
    </>
  )
}

//export default App;

const mapStateToProps = (state) => {
  return {

  };
};
const mapDispatchToProps = dispatch => {
  return {
    handleItems: (items) => dispatch({ items: items, type: 'ITEMS' }),
    handleCategories: (categories) => dispatch({ categories: categories, type: 'CATEGORIES' }),
    handleItemTypes: (itemTypes) => dispatch({ itemTypes: itemTypes, type: 'ITEMTYPES' }),
    handleFooters: (footers) => dispatch({ footers: footers, type: 'FOOTERS' }),
    handleSystems: (systems) => dispatch({ systems: systems, type: 'SYSTEMS' })
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
