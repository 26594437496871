export const changeContentReducer = function (state = [], action) {
  switch (action.type) {
    case "CATEGORIES":
      return { ...state, categories: action.categories };

    case "ITEMS":
      return { ...state, items: action.items };
    case "ITEMTYPES":
      return { ...state, itemTypes: action.itemTypes };
    case "FOOTERS":
      return { ...state, footers: action.footers };
    case "SYSTEMS":
      return { ...state, systems: action.systems };

    default:
      return state;
  }
};
