import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getCategoiresItems } from "../initLoad";
import Category from "./Category";
const queryString = require('query-string');

const ItemsList = (props) => {
    const { match } = props
    const limit = 100;
    const [name, setName] = useState(match.params.name || "")
    const history = useHistory();

    const [categoires, setCategories] = useState([]);
    const [types, setTypes] = useState([]);
    const [years, setYears] = useState([]);
    const [page, setPage] = useState(0);
    const [lastElement, setLastElement] = useState(false);
    const [itemsData, setItemsData] = useState([]);
    const [filterList, setFilterList] = useState(null)
    const [pathName, setPathName] = useState(props.location.pathname);
    const [short, setShort] = useState("date-desc");

    useEffect(() => {
        if (pathName !== props.location.pathname) {
            window.location.search = ""
            setPathName(props.location.pathname);
            setFilterList({ c: [], t: [], y: [] })
            setShort("date-desc");
        }

    }, [props])



    useEffect(() => {
        setName(match.params.name || "")
    }, [match])


    useEffect(() => {
        changeFilter();
        setFilterList({ ...filterList, o: short })
    }, [short, name])

    useEffect(() => {
        const parsed = queryString.parse(window.location.search);
        const filter = { c: [], t: [], y: [] };
        for (let key in parsed) {
            if (key === "t" || key === "c" || key === "y") {
                filter[key] = parsed[key].split(" ");
            }
            else {
                filter[key] = parsed[key];

            }
        }
        setFilterList(filter);
        if (filter["o"]) {
            setShort(filter["o"])
        }
    }, [])

    useEffect(() => {
        if (!filterList) {
            return false;
        }
        const filterItems = [];
        for (let key in filterList) {
            if (filterList[key].length > 0) {
                if (Array.isArray(filterList[key])) {
                    filterItems.push(`${key}=${filterList[key].join("+")}`);
                }
                else {
                    filterItems.push(`${key}=${filterList[key]}`);
                }
            }
        }
        let path = `${window.location.pathname}${filterItems.length > 0 ? "?" + filterItems.join("&") : ""}`;
        history.push(path);
        changeFilter();
    }, [filterList])

    const changeFilter = async () => {
        setPage(0);
        let newPageItems = { items: [] };
        newPageItems = await getCategoiresItems(name, { ...filterList, "p": 0, "o": short })
        if (newPageItems.items && newPageItems.items.length < limit) {
            setLastElement(true);
        }
        console.log(newPageItems)
        setItemsData({ name: newPageItems.name, items: newPageItems.items });
        setTypes(newPageItems.types)
        setCategories(newPageItems.categories)
        setYears(newPageItems.years)
    }

    const getNextPage = async () => {
        const getPage = page + 1;
        setPage(getPage);
        let newPageItems = { items: [] };
        if (!lastElement) {
            newPageItems = await getCategoiresItems(name, { ...filterList, "p": getPage, "o": short })
        }
        if (newPageItems.items.length < limit) {
            setLastElement(true);
        }
        setItemsData({ ...itemsData, items: itemsData.items.concat(newPageItems.items) });
    }

    return (
        <>
            {itemsData && <Category
                filterList={filterList}
                setFilterList={setFilterList}
                items={itemsData.items}
                name={itemsData.name}
                getNextPage={getNextPage}
                hasMore={lastElement}
                filterCategories={categoires}
                filterTypes={types}

                filterYears={years}

                short={short}
                setShort={setShort}
            />}
        </>
    )
};

export default ItemsList;