import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getPage } from '../initLoad';
import ReactHtmlParser from 'react-html-parser';

const Page = ({ match }) => {
    const [page, setPage] = useState(null);
    const history = useHistory();
    const changePath = (path) => {
        window.scrollTo(0, 0);
        history.push(path);
    }
    if (!match.url) {
        changePath("/")
    }
    useEffect(() => {
        getPage(match.url, setPage);
    }, [])

    useEffect(() => {
        if (page !== null && (!page || page.length === 0)) {
            changePath("/")
        }
    }, [page])

    return (<>
        {page && <>
            <div class="breadcumb_area">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <ol class="breadcrumb d-flex align-items-center">
                                <li class="breadcrumb-item"><a href="/">Főoldal</a></li>
                                <li class="breadcrumb-item active">{page.title}</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section class="section_padding_0_100">
                <div className="line"></div>
                <div className="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="section_heading text-center wow fadeInDown">
                                <h3>{page.title}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container">
                    {ReactHtmlParser(page.description)}
                </div>
            </section>
        </>}
    </>)
}

export default Page